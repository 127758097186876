import * as React from 'react';
import { SVGProps } from 'react';

const DiscordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M33.862 2.527A32.98 32.98 0 0 0 25.72.002a.125.125 0 0 0-.13.062c-.352.625-.741 1.44-1.014 2.083a30.47 30.47 0 0 0-9.145 0 20.964 20.964 0 0 0-1.03-2.083.128.128 0 0 0-.13-.062 32.86 32.86 0 0 0-8.143 2.525.115.115 0 0 0-.053.047C.889 10.32-.532 17.879.165 25.34a.136.136 0 0 0 .052.094c3.421 2.513 6.736 4.038 9.989 5.05a.128.128 0 0 0 .14-.047 23.745 23.745 0 0 0 2.043-3.324.127.127 0 0 0-.069-.176A21.78 21.78 0 0 1 9.2 25.45a.128.128 0 0 1-.013-.213c.21-.157.42-.321.62-.486a.123.123 0 0 1 .13-.017c6.546 2.99 13.633 2.99 20.103 0a.123.123 0 0 1 .13.015c.2.166.41.33.621.488a.128.128 0 0 1-.01.213 20.463 20.463 0 0 1-3.122 1.485.128.128 0 0 0-.069.178c.601 1.164 1.287 2.271 2.042 3.322a.126.126 0 0 0 .14.047c3.269-1.01 6.583-2.536 10.004-5.049a.126.126 0 0 0 .052-.092c.834-8.628-1.397-16.123-5.914-22.767a.101.101 0 0 0-.052-.048Zm-20.495 18.27c-1.971 0-3.595-1.81-3.595-4.032 0-2.223 1.593-4.032 3.595-4.032 2.018 0 3.626 1.825 3.594 4.032.001 2.222-1.592 4.032-3.594 4.032Zm13.292 0c-1.971 0-3.595-1.81-3.595-4.032 0-2.223 1.593-4.032 3.595-4.032 2.018 0 3.626 1.825 3.594 4.032 0 2.222-1.576 4.032-3.594 4.032Z"
      fill="#fff"
    />
  </svg>
);

export default DiscordIcon;
