import { SVGProps } from 'react';

const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={props.opacity || 0.6} clipPath="url(#a)">
      <path d="M13.848 0h.143c.114 1.41-.424 2.464-1.079 3.228-.642.757-1.52 1.493-2.943 1.381-.094-1.39.445-2.366 1.098-3.127C11.673.772 12.784.14 13.848 0ZM18.153 14.681v.04c-.4 1.21-.97 2.247-1.665 3.21-.635.873-1.413 2.05-2.803 2.05-1.2 0-1.998-.773-3.228-.794-1.302-.02-2.017.646-3.207.813h-.406c-.874-.126-1.58-.818-2.093-1.442-1.515-1.842-2.686-4.222-2.903-7.268v-.895c.092-2.18 1.151-3.952 2.559-4.81.743-.457 1.764-.847 2.902-.673.487.076.985.243 1.421.408.414.159.931.44 1.421.426.332-.01.662-.183.997-.305.98-.354 1.941-.76 3.207-.569 1.522.23 2.603.906 3.27 1.95-1.287.819-2.305 2.054-2.131 4.163.154 1.915 1.268 3.036 2.659 3.696Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default AppleIcon;
