import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.433 10a14.278 14.278 0 0 1-4.1 1.15 7.164 7.164 0 0 0 3.134-3.967 14.402 14.402 0 0 1-4.534 1.75c-1.316-1.433-3.166-2.266-5.266-2.266-3.917 0-7.117 3.2-7.117 7.15 0 .566.067 1.116.183 1.633A20.324 20.324 0 0 1 5 7.983a7.074 7.074 0 0 0-.967 3.583c0 2.484 1.25 4.684 3.184 5.934-1.184 0-2.284-.334-3.25-.834v.05A7.155 7.155 0 0 0 9.7 23.733a7.033 7.033 0 0 1-3.217.117 7.133 7.133 0 0 0 6.667 4.966c-2.527 2.001-5.66 3.083-8.883 3.067-.567 0-1.134-.033-1.7-.1A20.24 20.24 0 0 0 13.533 35c13.134 0 20.35-10.9 20.35-20.35 0-.317 0-.617-.016-.934 1.4-1 2.6-2.266 3.566-3.716Z"
      fill="#fff"
    />
  </svg>
);

export default TwitterIcon;
