import * as React from 'react';
import { SVGProps } from 'react';

const DefaultImpaktProfileIcon = ({ width = '283', height = '283' }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 283 283"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="283" height="283" rx="37" fill="#364A63" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.278 58.4714C186.875 58.8424 184.616 59.7669 183.257 60.5257C181.858 61.3071 159.362 82.0913 131.386 108.452C83.4788 153.592 81.9252 155.118 79.9849 158.93C76.299 166.17 76.116 167.887 76.2996 193.518L76.463 216.315L78.0312 219.315C84.073 230.874 100.867 230.192 106.738 218.15C107.471 216.648 107.701 213.277 108.008 199.545C108.391 182.406 108.59 180.821 110.898 176.486C113.794 171.047 118.219 169.208 123.46 171.265C124.845 171.809 134.588 180.56 153.428 198.183C184.171 226.939 184.241 226.994 191.186 227.465C198.409 227.954 204.885 224.547 208.287 218.468C209.589 216.142 209.786 215.173 209.79 211.075C209.795 206.817 209.633 206.079 208.122 203.487C206.872 201.342 198.699 193.285 175.801 171.626L145.155 142.636L176.139 113.443C200.145 90.8249 207.408 83.6813 208.387 81.7265C211.177 76.1556 210.286 69.0277 206.192 64.1617C202.196 59.4121 194.255 56.8902 188.278 58.4714ZM88.9037 79.8896C82.526 80.6616 76.3291 85.0062 73.4896 90.6976C72.2031 93.276 72 94.3608 72 98.6614C72 102.946 72.2053 104.051 73.47 106.585C75.4399 110.531 78.2806 113.372 82.1712 115.286C86.1946 117.267 87.7951 117.625 92.4417 117.586C97.462 117.545 101.656 115.997 105.355 112.821C120.359 99.9396 109.121 77.4433 88.9037 79.8896Z"
      fill="#CC1338"
    />
  </svg>
);

export default DefaultImpaktProfileIcon;
