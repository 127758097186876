export default {
  common: {
    impakt: 'Impakt',
    downloadTutorial: 'Download Tutorial',
  },
  impaktGamesHero: {
    social: 'Social.',
    fitness: 'Fitness.',
    gamified: 'Gamified.',
  },
  downloadPlateform: {
    download: 'Download',
    platform: 'our App',
  },
  navbar: {
    events: 'Events',
    contactUs: 'Contact Us',
    impaktFitness: 'Impakt Fitness',
    knowledgeBase: 'Knowledge Base',
    download: 'Download Platform',
    dashboard: 'Dashboard',
    signOut: 'Sign Out',
    signIn: 'Sign in',
  },
  computerVision: {
    computerVision: 'Computer vision',
    technology: 'Technology',
    description: 'Use your body as a controller - move, sweat, get fit.',
    hardwareCard: 'No extra hardware necessary',
    cameraCard: 'Use the camera on your phone or laptop',
  },
  burnAndEarn: {
    earn: 'Earn',
    burnAnd: 'Burn and',
    description: 'Connect ANYTIME with ANYONE, ANYWHERE, workout, battle and earn tokens and NFTs!',
  },
  ourAdvisor: {
    our: 'Our',
    advisor: 'advisors',
  },
  ourTeam: {
    our: 'Leadership',
    team: 'team',
  },
  ourRoadmap: {
    our: 'Our',
    roadmap: 'Roadmap',
    q4_1: 'Develop Computer Vision System',
    q1_1: 'Alpha Test App',
    q1_2: 'Daily/Weekly Routines',
    q1_3: 'Centralized Economy',
    q2_1: 'Genesis NFT Mint',
    q2_2: 'Decentralized Economy',
    q3_1: 'Release SDK',
  },
  contactUs: {
    us: 'Us',
    send: 'Send',
    contact: 'Contact',
    yourName: 'Your name',
    yourMessage: 'Your message',
    yourEmail: 'Your email',
  },
  events: {
    noEventsForNow: 'No events for now',
    waitForUpdates: 'Wait for updates',
  },
  contact: {
    getInTouch: 'Get in Touch',
    withUs: 'with Us',
    sendUsMessage: 'Send us a Message',
    send: 'Send',
    contact: 'Contact',
    yourName: 'Your name',
    yourMessage: 'Your message',
    yourEmail: 'Your email',
    topicOfMessage: 'Topic of message',
    joinOur: 'Join our',
    discordSupport: 'Discord Support',
  },
  footer: {
    madeBy: 'Made by',
    termOfUse: 'Term of Use',
    allRightReserved: '© 2021 Impakt. All rights reserved.',
  },
  password: {
    new: 'New',
    recovery: 'Recover',
    password: 'Password',
    recoveryText: 'We’ll email you instructions to reset your password',
    yourEmail: 'Your email',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    changeText: 'Choose a unique password to protect your account',
  },
  signUp: {
    createAn: 'Create an',
    account: 'account',
    yourEmail: 'Email',
    fourDigit: '0000',
    memberName: `Membername`,
  },
  signIn: {
    signIn: `Sign in`,
    createAn: `Create an account`,
    firstTime: `First time?`,
    signInButton: `Sign In`,
    email: `Email...`,
  },
  verification: {
    verification: 'Verification',
    successful: 'successful',
    Successtitle: 'You can close this page now.',
    thankyou: 'Thank you!',
    something: 'Something went',
    wrong: 'wrong',
    message: 'Perhaps the link has expired or something went wrong. Please try again.',
    loading: 'loading',
  },
  memberDashboard: {
    referrals: {
      headline: 'Referrals',
      subHeadline: 'Refer friends and earn GODL',
      description:
        'For each member who joins through your referral link and completes at least 5 daily, weekly or targeted challenges with Impakt, you receive +1000 GODL and they receive +5000 GODL.',
    },
    yourReferralLink: `Your referral link:`,
    whitelist: {
      headline: 'Whitelist Challenge',
      subHeadline: 'Top 2500 members on the member whitelist leaderboard will get whitelisted.',
      description: 'Your total score & rank on the leaderboard is determined by summing up:',
      description2: 'It pays to workout with your friends!',
      item1: 'All your workout scores',
      item2: "5% of your referred friends' workout scores",
      item3: "2% of your referred friends' referrals' workout scores",
    },
  },
};
