export default {
  brand: {},
  general: {
    dark: '#282A2E',
    light: '#FFFFFF',
  },
  accentR1: '#DC143C',
  accentR2: '#B22222',
  accentRed: 'linear-gradient(143.78deg, #DC143C 18.94%, #B22222 78.86%)',
  accentGreen: '#40DC1A',
  accentBlackGradient: 'linear-gradient(150.95deg, #363639 15.07%, #222226 82.14%)',
  bg: {
    dark: '#282A2E',
    light: '#F3F3FB',
  },
  shape: {
    dark: '#1F2024',
    light: '#FFFFFF',
  },
  glass: {
    0: '#FFFFFF',
    100: '#F8FAFC',
    200: '#F1F4F9',
    300: '#E4EAF1',
    400: '#778FAD',
    500: '#587393',
    600: '#364A63',
    700: '#1C2C40',
    800: '#14202E',
    900: '#0B1725',
    1000: '#05070B',
  },
  crimson: {
    0: '#DC143C',
    100: '#CC1338',
    200: '#B22222',
  },
  electric: {
    100: '#F5F6FF',
    200: '#E6E8FF',
    250: '#B4BBFE',
    300: '#6A79FD',
    400: '#2137FC',
    500: '#0317CE',
    600: '#021194',
    700: '#020C65',
  },
  yellow: {
    100: '#FFFBF5',
    200: '#FFE8BD',
    300: '#FFCA66',
    400: '#FFB01A',
  },
  green: {
    100: '#F6FEF7',
    200: '#7ECE86',
    300: '#1AB329',
    400: '#0FA41E',
  },
  red: {
    100: '#FFF5F5',
    200: '#FECDCF',
    300: '#FC7377',
    400: '#FA0F17',
  },
};
