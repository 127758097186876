import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import Images from 'assets/images';
import AnimationInWhenVisible from 'components/common/AnimationInWhenVisible';
import HeroLayout from 'components/layouts/HeroLayout';
// import { StatusQuoBlurs } from './StatusQuoBlurs';
import StatusQuoCard from './StatusQuoCard';

const StatusQuoHero = () => {
  return (
    <HeroLayout minH="70vh">
      <VStack
        background="radial-gradient(40% 35% at 50% 70%,rgba(184, 50, 108, 0.5) 0%, rgba(184, 50, 108, 0) 90%)"
        w="full"
        color="white"
        align="center"
        justify="center"
        pt={{ base: '120px', md: '0px' }}
      >
        <Text textStyle="bold7" maxW={{ base: '400px', md: '60%' }} align="center">
          The only platform where jumping jacks earn you crypto
        </Text>
        <VStack
          w="full"
          maxW="1232px"
          marginTop="70px !important"
          pos="relative"
          align="center"
          spacing="32px"
          justify={{ base: 'center', md: 'space-between' }}
          pt={{ base: '16px', md: '0' }}
        >
          <Image
            zIndex={10}
            maxH="358px"
            d={{ base: 'flex', lg: 'none' }}
            src={Images.Common.blitzChallenge}
          />
          <HStack
            w="full"
            align="center"
            marginTop="0 !important"
            justify={{ base: 'center', lg: 'space-between' }}
          >
            <AnimationInWhenVisible isLeft animationType="move">
              <StatusQuoCard />
              {/* <StatusQuoBlurs /> */}
            </AnimationInWhenVisible>
            <AnimationInWhenVisible animationType="move">
              <Image
                zIndex={11}
                maxH="358px"
                d={{ base: 'none', lg: 'flex' }}
                src={Images.Common.blitzChallenge}
              />
            </AnimationInWhenVisible>
          </HStack>
        </VStack>
      </VStack>
    </HeroLayout>
  );
};

export default StatusQuoHero;
