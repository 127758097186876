import * as React from 'react';
import { SVGProps } from 'react';

const NftImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.875 6.875h1.25V30h-1.25V6.875ZM6.875 1.875h23.75v1.25H6.875v-1.25Z" fill="#fff" />
    <path
      d="M35.625 26.381V6.875h-1.25v19.019a6.41 6.41 0 0 0-1.25-.238V5.625h3.125A.625.625 0 0 0 36.875 5V1.25a.625.625 0 0 0-.625-.625H32.5a.625.625 0 0 0-.625.625v3.125H5.625V2.5A.625.625 0 0 0 5 1.875H2.5a.625.625 0 0 0-.625.625V5a.625.625 0 0 0 .625.625h1.875V31.25H1.25a.625.625 0 0 0-.625.625v3.75a.625.625 0 0 0 .625.625H5a.625.625 0 0 0 .625-.625V32.5h20c.001.42.041.838.119 1.25H6.875V35H26.1a6.876 6.876 0 1 0 9.525-8.619Zm-2.5-24.506h2.5v2.5h-2.5v-2.5ZM4.375 35h-2.5v-2.5h2.5V35Zm0-30.625h-1.25v-1.25h1.25v1.25ZM25.744 31.25H5.625V5.625h26.25v20.031a6.875 6.875 0 0 0-6.131 5.594Zm6.756 6.875a5.625 5.625 0 1 1 0-11.25 5.625 5.625 0 0 1 0 11.25Z"
      fill="#fff"
    />
    <path
      d="m35.625 30.538-2.813-1.407a.626.626 0 0 0-.562 0l-2.813 1.407a.625.625 0 0 0-.343.556v2.812a.624.624 0 0 0 .343.557l2.813 1.406a.624.624 0 0 0 .563 0l2.812-1.406a.625.625 0 0 0 .344-.557v-2.812a.624.624 0 0 0-.344-.556Zm-.938 2.98L32.5 34.614l-2.188-1.094V31.48l2.188-1.093 2.188 1.093v2.038ZM29.819 22.68l-4.42-4.418a.624.624 0 0 0-.887 0l-1.343 1.344-5.763-5.763a.623.623 0 0 0-.444-.18.656.656 0 0 0-.443.18L7.68 22.681l.888.887 8.393-8.4 5.32 5.325-2.188 2.188.88.887 3.982-3.98 3.975 3.98.888-.887ZM23.125 15.625a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0-3.75a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z"
      fill="#fff"
    />
  </svg>
);

export default NftImageIcon;
