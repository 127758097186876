/* eslint-disable no-unreachable */
/* eslint-disable global-require */

const Images = {
  dark: require('./dark.png'),
  light: require('./light.png'),
  star: require('./star.png'),
  Common: {
    Logo: require('./logo.png'),
    HighResLogo: require('./high-res-impakt-logo.png'),
    LogoLight: require('./logo-light.png'),
    Twitter: require('./twitter.png'),
    TwitterLight: require('./twitter-light.png'),
    Youtube: require('./youtube-dark.png'),
    YoutubeLight: require('./youtube-light.png'),
    Discord: require('./discord.png'),
    DiscordLight: require('./discord-light.png'),
    Header: require('./bg-header.png'),
    Peter: require('./video.webp'),
    Peter404: require('./peter404.png'),
    Laptop: require('./laptop.svg'),
    blitzChallenge: require('./blitz-challenge.png'),
    bgWave: require('./bg-wave.png'),
    play: require('./play.png'),
  },

  motionCapture: require('./motion-capture.webp'),
  burnAndEarn: require('./burn-to-earn-hero.webp'),
  computerVision: require('./computer-vision.png'),
  burnAndEarnPeople: require('./burnAndEarnPeople.png'),
  impaktLaptop: require('./impakt-laptop.png'),
  joinOurCommunity: require('./join-our-community.png'),
  downloadlaptop: require('./download.png'),
  verification: require('./rich-icon.png'),
  wrong: require('./some-wrong.png'),
  contact: {
    md: require('./contact.webp'),
    sm: require('./contact.webp'),
    xl: require('./contact.webp'),
  } as any,

  impaktGames: {
    Header: require('./bg.png'),
    light: require('./bg-light.png'),
  },
  advisor: {
    kevin: require('./advisor/kevin-lin.webp'),
    tim: require('./advisor/tim.png'),
    kai: require('./advisor/kai-huang.webp'),
  },
  team: {
    cap: require('./team/cap.png'),
    dahaka: require('./team/dahaka.png'),
    demideus: require('./team/demideus.png'),
    duke: require('./team/duke.png'),
    valtec: require('./team/valtec.png'),
    dukeOfDeadfall: require('./team/duke-of-deadfall.png'),
  },
  contactUs: {
    discordPeter: require('./discordPeter.png'),
    hiPeter: require('./hiPeter.png'),
  },
};

export default Images;
