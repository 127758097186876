const common = 'common';
const contact = 'contact';
const events = 'events';
const ourRoadmap = 'ourRoadmap';
const ourAdvisor = 'ourAdvisor';
const ourTeam = 'ourTeam';
const navbar = 'navbar';
const burnAndEarn = 'burnAndEarn';
const computerVision = 'computerVision';
const impaktGamesHero = 'impaktGamesHero';
const contactUs = 'contactUs';
const footer = 'footer';
const downloadPlateform = 'downloadPlateform';
const password = 'password';
const verification = 'verification';
const signUp = 'signUp';
const signIn = 'signIn';
const memberDashboard = 'memberDashboard';

const TranslationKeys = {
  common: {
    impakt: `${common}.impakt`,
    downloadTutorial: `${common}.downloadTutorial`,
  },
  impaktGamesHero: {
    social: `${impaktGamesHero}.social`,
    fitness: `${impaktGamesHero}.fitness`,
    gamified: `${impaktGamesHero}.gamified`,
  },
  downloadPlateform: {
    download: `${downloadPlateform}.download`,
    platform: `${downloadPlateform}.platform`,
  },
  navbar: {
    events: `${navbar}.events`,
    contactUs: `${navbar}.contactUs`,
    impaktFitness: `${navbar}.impaktFitness`,
    knowledgeBase: `${navbar}.knowledgeBase`,
    download: `${navbar}.download`,
    dashboard: `${navbar}.dashboard`,
    signOut: `${navbar}.signOut`,
    signIn: `${navbar}.signIn`,
  },
  computerVision: {
    computerVision: `${computerVision}.computerVision`,
    technology: `${computerVision}.technology`,
    description: `${computerVision}.description`,
    hardwareCard: `${computerVision}.hardwareCard`,
    cameraCard: `${computerVision}.cameraCard`,
  },
  burnAndEarn: {
    earn: `${burnAndEarn}.earn`,
    burnAnd: `${burnAndEarn}.burnAnd`,
    description: `${burnAndEarn}.description`,
  },
  ourAdvisor: {
    our: `${ourAdvisor}.our`,
    advisor: `${ourAdvisor}.advisor`,
  },
  ourTeam: {
    our: `${ourTeam}.our`,
    team: `${ourTeam}.team`,
  },
  ourRoadmap: {
    our: `${ourRoadmap}.our`,
    roadmap: `${ourRoadmap}.roadmap`,
    q4_1: `${ourRoadmap}.q4_1`,
    q1_1: `${ourRoadmap}.q1_1`,
    q1_2: `${ourRoadmap}.q1_2`,
    q1_3: `${ourRoadmap}.q1_3`,
    q2_1: `${ourRoadmap}.q2_1`,
    q2_2: `${ourRoadmap}.q2_2`,
    q3_1: `${ourRoadmap}.q3_1`,
  },
  contactUs: {
    us: `${contactUs}.us`,
    send: `${contactUs}.send`,
    contact: `${contactUs}.contact`,
    yourName: `${contactUs}.yourName`,
    yourMessage: `${contactUs}.yourMessage`,
    yourEmail: `${contactUs}.yourEmail`,
  },
  events: {
    noEventsForNow: `${events}.noEventsForNow`,
    waitForUpdates: `${events}.waitForUpdates`,
  },
  contact: {
    getInTouch: `${contact}.getInTouch`,
    withUs: `${contact}.withUs`,
    sendUsMessage: `${contact}.sendUsMessage`,
    send: `${contact}.send`,
    contact: `${contact}.contact`,
    yourName: `${contact}.yourName`,
    yourMessage: `${contact}.yourMessage`,
    yourEmail: `${contact}.yourEmail`,
    topicOfMessage: `${contact}.topicOfMessage`,
    joinOur: `${contact}.joinOur`,
    discordSupport: `${contact}.discordSupport`,
  },
  footer: {
    madeBy: `${footer}.madeBy`,
    termOfUse: `${footer}.termOfUse`,
    allRightReserved: `${footer}.allRightReserved`,
  },
  password: {
    recovery: `${password}.recovery`,
    password: `${password}.password`,
    recoveryText: `${password}.recoveryText`,
    yourEmail: `${password}.yourEmail`,
    newPassword: `${password}.newPassword`,
    confirmPassword: `${password}.confirmPassword`,
    changeText: `${password}.changeText`,
    new: `${password}.new`,
  },
  signUp: {
    createAn: `${signUp}.createAn`,
    account: `${signUp}.account`,
    memberName: `${signUp}.memberName`,
    fourDigit: `${signUp}.fourDigit`,
    email: `${signUp}.yourEmail`,
  },
  signIn: {
    signIn: `${signIn}.signIn`,
    createAn: `${signIn}.createAn`,
    firstTime: `${signIn}.firstTime`,
    signInButton: `${signIn}.signInButton`,
    email: `${signIn}.email`,
  },
  verification: {
    verification: `${verification}.verification`,
    loading: `${verification}.loading`,
    successful: `${verification}.successful`,
    thankyou: `${verification}.thankyou`,
    Successtitle: `${verification}.Successtitle`,
    something: `${verification}.something`,
    wrong: `${verification}.wrong`,
    message: `${verification}.message`,
  },
  memberDashboard: {
    referrals: {
      headline: `${memberDashboard}.referrals.headline`,
      subHeadline: `${memberDashboard}.referrals.subHeadline`,
      description: `${memberDashboard}.referrals.description`,
    },
    yourReferralLink: `${memberDashboard}.yourReferralLink`,
    whitelist: {
      headline: `${memberDashboard}.whitelist.headline`,
      subHeadline: `${memberDashboard}.whitelist.subHeadline`,
      description: `${memberDashboard}.whitelist.description`,
      description2: `${memberDashboard}.whitelist.description2`,
      item1: `${memberDashboard}.whitelist.item1`,
      item2: `${memberDashboard}.whitelist.item2`,
      item3: `${memberDashboard}.whitelist.item3`,
    },
  },
};

export default TranslationKeys;
