export default {
  common: {
    lang: 'en',
    title: 'Impakt',
    meta: [
      {
        name: 'description',
        content: `Impakt is a fitness platform where you can meet and get fit with other people around the world. Compete individually or in teams using computer vision technology and earn crypto.`,
      },
      {
        name: 'keywords',
        content: ['impakt', 'social', 'fitness', 'gamified'].join(', '),
      },
    ],
  },
  blog: {
    title: 'Knowledge Base',
    meta: [
      {
        name: 'description',
        content: 'Impakt blog',
      },
      {
        name: 'keywords',
        content: ['blog', 'impakt', 'social', 'fitness', 'gamified'].join(', '),
      },
    ],
  },
};
