import { theme } from '@chakra-ui/react';

export default {
  black10: {
    fontSize: '120px',
    fontWeight: '900',
    lineHeight: '114px',
  },
  black9: {
    fontSize: '90px',
    fontWeight: '900',
    lineHeight: '90px',
  },
  black8: {
    fontSize: '72px',
    fontWeight: '900',
    lineHeight: '72px',
  },
  black7: {
    fontSize: '56px',
    fontWeight: '900',
    lineHeight: '60px',
  },
  bold7: {
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '60px',
  },
  light7: {
    fontSize: '56px',
    fontWeight: '300',
    lineHeight: '60px',
  },
  bold6: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '40px',
  },
  regular6: {
    fontSize: '40px',
    fontWeight: '400',
    lineHeight: '40px',
  },
  light6: {
    fontSize: '40px',
    fontWeight: '300',
    lineHeight: '40px',
  },
  bold5: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '36px',
  },
  regular5: {
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '36px',
  },
  bold4: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  regular4: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '32px',
  },
  bold3: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  semiBold3: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  regular3: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  bold2: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
  },
  regular2: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  bold1: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
  },
  regular1: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
  },

  regular20: {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '32px',
  },
  semiBold16: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  regular14: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  regular12: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
  },
  counterNumber: {
    fontFamily: 'Source Sans Pro',
    fontWeight: '300',
    fontSize: { base: '7xl', md: '9xl' },
    lineHeight: '120px',
  },
  counterText: {
    fontFamily: 'Source Sans Pro',
    fontSize: { base: 'xl', md: '4xl' },
    opacity: '0.7',
  },
  TitleBold72: {
    fontWeight: '900',
    fontFamily: `Poppins ,${theme.fonts?.heading}`,
    fontSize: { base: '72px', sm: '120px', md: '98px', xl: '120px' },
    lineHeight: { base: '80px', sm: '120px', md: '98px', xl: '120px' },
  },
  accentGradient: {
    bgClip: 'text',
    bgGradient: 'linear(to-r, rgba(220, 20, 60, 1), rgba(178, 34, 34, 1))',
    _hover: {
      bgGradient: 'linear(to-r, rgba(102, 14, 31, 1), rgba(158, 57, 57, 1))',
    },
  },
};
