import Play from './PlayIcon';
import ProfileUser from './ProfileUserIcon';
import VrGlass from './VrGlassIcon';
import Runner from './RunnerIcon';
import WhitePlay from './WhitePlayIcon';
import NftGame from './NftGameIcon';
import NftImage from './NftImageIcon';
import Facebook from './FacebookIcon';
import Google from './GoogleIcon';
import Apple from './AppleIcon';
import Twitter from './TwitterIcon';
import Discord from './DiscordIcon';
import SmallTwitter from './SmallTwitterIcon';
import LeftArrow from './LeftArrowIcon';
import RightArrow from './RightArrowIcon';
import Window from './WindowIcon';
import Cross from './CrossIcon';
import CheckMark from './CheckMarkIcon';
import UnCheckMark from './UnCheckMarkIcon';
import Eye from './Eye';
import EyeOff from './EyeOff';

const Icons: { [key in string]: any } = {
  ProfileUser,
  Play,
  Window,
  VrGlass,
  Runner,
  WhitePlay,
  NftGame,
  NftImage,
  Facebook,
  Google,
  Apple,
  Cross,
  Twitter,
  Discord,
  CheckMark,
  UnCheckMark,
  LeftArrow,
  RightArrow,
  SmallTwitter,
  EyeOff,
  Eye,
};

export default Icons;
