export default {
  common: {
    impakt: '硬派客',
    downloadTutorial: '下载教程',
  },
  impaktGamesHero: {
    social: '社交',
    fitness: '健身',
    gamified: '游戏金融',
  },
  navbar: {
    events: '活动',
    contactUs: '联系我们',
    knowledgeBase: '知识库',
    impaktFitness: '硬派客健身',
  },
  computerVision: {
    computerVision: '机器视觉',
    technology: '技术',
    description: '用你的身体作为控制器 移动 出汗 健身',
    hardwareCard: '无需额外硬件',
    cameraCard: '用你手机或电脑的摄像头',
  },
  burnAndEarn: {
    burnAnd: '燃脂并且',
    earn: '赚钱',
    description: '任何时间、任何地点连线任何人，一起健身、对战并且赢取代币和NFT！',
  },
  ourAdvisor: {
    our: '我们的',
    advisor: '顾问',
  },
  ourTeam: {
    our: '我们的',
    team: '团队',
  },
  ourRoadmap: {
    our: '我们的',
    roadmap: '路线图',
    q4_1: '开发机器视觉系统',
    q1_1: '公测版本发布',
    q1_2: '每日/每周任务列表',
    q1_3: '中心化经济体系',
    q2_1: '创世NFT铸币',
    q2_2: '去中心化经济体系',
    q3_1: '发布SDK',
  },
  contactUs: {
    us: '我们',
    send: '发送',
    contact: '联系',
    yourName: '你的姓名',
    yourMessage: '你的留言',
    yourEmail: '你的电子邮箱',
  },
  events: {
    noEventsForNow: '目前没有任何事件',
    waitForUpdates: '等待更新',
  },
  contact: {
    getInTouch: '取得联系',
    withUs: '与我们',
    sendUsMessage: '给我们留言',
    send: '发送',
    contact: '联系',
    yourName: '你的姓名',
    yourMessage: '你的留言',
    yourEmail: '你的电子邮箱',
    topicOfMessage: '邮件的主题',
    joinOur: '加入我们',
    discordSupport: 'Discord支持',
  },
  footer: {
    madeBy: '制造',
    termOfUse: '使用条款',
    allRightReserved: '© 2021 硬派客。保留所有权利。',
  },
};
