const SignOutIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 7.42681C0.0132357 7.29486 0.0248671 7.1633 0.0397072 7.03175C0.152813 6.04027 0.495739 5.13462 1.06768 4.31762C1.6653 3.46411 2.43698 2.81195 3.3719 2.35271V2.35271C3.39614 2.34127 3.4257 2.35909 3.42669 2.38587C3.42679 2.38873 3.42685 2.39158 3.42685 2.39443C3.42725 3.00969 3.42645 3.62495 3.42806 4.24061C3.42806 4.28433 3.41562 4.3116 3.38033 4.33928C2.52401 5.0135 1.9633 5.87943 1.78642 6.95674C1.52572 8.54383 2.01023 9.88826 3.21308 10.9587C3.81711 11.4966 4.53384 11.8179 5.3332 11.9398C7.66109 12.2952 9.71944 10.7654 10.1823 8.63046C10.5373 6.99404 9.94084 5.37006 8.61566 4.33406C8.59521 4.31802 8.57355 4.28714 8.57355 4.26307C8.57154 3.62695 8.57235 2.99044 8.57275 2.35432V2.35432C8.57275 2.34279 8.5878 2.33711 8.5986 2.34114C8.6108 2.34569 8.62271 2.35022 8.63411 2.35592C10.3046 3.20822 11.3859 4.5338 11.844 6.35793C11.9262 6.68601 11.9715 7.02092 11.988 7.35903V7.35903C11.9904 7.41381 12 7.46798 12 7.52281C12 7.65652 12 7.79023 12 7.92394C12 7.97454 11.9904 8.02442 11.988 8.07496V8.07496C11.9667 8.4596 11.9094 8.83943 11.8087 9.21123C11.3743 10.8172 10.4394 12.0461 9.01073 12.8952C8.29279 13.3219 7.51309 13.5738 6.68244 13.6661C6.54848 13.6809 6.41412 13.6929 6.28016 13.7062C6.11419 13.7062 5.94852 13.7062 5.78263 13.7062C5.73203 13.7062 5.68215 13.6965 5.63161 13.6941V13.6941C5.14108 13.6661 4.66058 13.5798 4.19372 13.4282C2.72776 12.9513 1.59992 12.0481 0.814198 10.7229C0.387045 10.0022 0.132357 9.22126 0.0401083 8.38781C0.0256693 8.25866 0.0136368 8.12951 0.000401083 8.00036C0 7.80905 0 7.61813 0 7.42681Z"
      fill="#B22222"
    />
    <path
      d="M6.03896 0.000387325C6.08718 0.000414712 6.13519 0.00716808 6.18169 0.0199295C6.22063 0.0306138 6.25954 0.041574 6.29736 0.0553494C6.63066 0.178883 6.85327 0.48892 6.85367 0.843878C6.85527 2.56613 6.85607 4.28878 6.85327 6.01103C6.85246 6.47709 6.46702 6.8517 6.00217 6.8529C5.53651 6.85411 5.14947 6.4831 5.14586 6.01664C5.14024 5.32438 5.14425 4.63211 5.14425 3.93984C5.14425 2.92831 5.15147 1.91678 5.14104 0.905244C5.13623 0.428357 5.47113 0.0653765 5.87061 0.0100271V0.0100271C5.88389 0.00836751 5.89538 4.1319e-05 5.90876 0.000100883C5.95206 0.000293653 5.99549 0.000362638 6.03896 0.000387325Z"
      fill="#B22222"
    />
  </svg>
);

export default SignOutIcon;
