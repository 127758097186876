import * as React from 'react';
import { SVGProps } from 'react';

const SmallTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={18}
    fill="none"
    {...props}
    opacity={0.6}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2.799a8.549 8.549 0 0 1-2.363.647 4.077 4.077 0 0 0 1.804-2.266 8.192 8.192 0 0 1-2.6.992A4.099 4.099 0 0 0 9.75 4.976c0 .325.027.638.095.935-3.409-.166-6.425-1.8-8.451-4.288A4.128 4.128 0 0 0 .83 3.694c0 1.42.732 2.679 1.821 3.408A4.05 4.05 0 0 1 .8 6.598v.045a4.119 4.119 0 0 0 3.285 4.028 4.09 4.09 0 0 1-1.075.135c-.262 0-.527-.015-.776-.07.531 1.624 2.038 2.818 3.831 2.856a8.238 8.238 0 0 1-5.084 1.75A7.67 7.67 0 0 1 0 15.284a11.543 11.543 0 0 0 6.29 1.84c7.545 0 11.67-6.25 11.67-11.668 0-.18-.006-.356-.015-.53A8.179 8.179 0 0 0 20 2.8Z"
      fill="#fff"
    />
  </svg>
);

export default SmallTwitterIcon;
