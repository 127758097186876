import * as React from 'react';
import { SVGProps } from 'react';

const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.79 12.912L9.176 11.297C8.55184 11.5202 7.87715 11.5615 7.23042 11.4162C6.58369 11.2709 5.99153 10.9449 5.52282 10.4762C5.05411 10.0075 4.72814 9.41531 4.58283 8.76858C4.43752 8.12185 4.47885 7.44716 4.702 6.823L2.642 4.763C0.938 6.278 0 8 0 8C0 8 3 13.5 8 13.5C8.9604 13.4967 9.90994 13.2965 10.79 12.912V12.912ZM5.21 3.088C6.09005 2.70342 7.03959 2.50331 8 2.5C13 2.5 16 8 16 8C16 8 15.061 9.721 13.359 11.238L11.297 9.176C11.5202 8.55184 11.5615 7.87715 11.4162 7.23042C11.2709 6.58369 10.9449 5.99153 10.4762 5.52282C10.0075 5.05411 9.41531 4.72814 8.76858 4.58283C8.12185 4.43752 7.44716 4.47885 6.823 4.702L5.21 3.089V3.088Z"
      fill="#fff"
    />
    <path
      d="M5.52548 7.646C5.47048 8.03031 5.50573 8.42215 5.62845 8.79047C5.75117 9.15879 5.95798 9.49347 6.2325 9.76799C6.50701 10.0425 6.84169 10.2493 7.21001 10.372C7.57833 10.4948 7.97017 10.53 8.35448 10.475L5.52448 7.646H5.52548ZM10.4755 8.354L7.64648 5.524C8.03079 5.46899 8.42263 5.50424 8.79096 5.62696C9.15928 5.74968 9.49396 5.95649 9.76847 6.23101C10.043 6.50553 10.2498 6.8402 10.3725 7.20853C10.4952 7.57685 10.5305 7.96869 10.4755 8.353V8.354ZM13.6465 14.354L1.64648 2.354L2.35448 1.646L14.3545 13.646L13.6465 14.354Z"
      fill="#fff"
    />
  </svg>
);

export default Eye;
